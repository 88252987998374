/* eslint-disable no-case-declarations */
import { Localization } from 'constant/config';
import { FC } from 'react';
import AuthTemplate from 'themes/AuthTemplate';
import CustomerTemplate from 'themes/CustomerTemplate';
import DealerTemplate from 'themes/DealerTemplate';
import DashboardTemplate from 'themes/DashboardTemplate';
import SuperuserTemplate from 'themes/SuperuserTemplate';

interface IRouterPattern {
  path: string;
  noAuth?: boolean;
  pageComponent: string;
  isExact?: boolean;
  layout?: FC;
}

let transactionRoutes: IRouterPattern[] = [];
if (Localization.activateTransactionListing) {
  transactionRoutes = [
    {
      path: '/transaction/:type/:id',
      pageComponent: 'Transaction/detail',
      layout: DashboardTemplate,
    },
    {
      path: '/transactions',
      pageComponent: 'Transaction/list',
      layout: DashboardTemplate,
    },
  ];
}

let memoRoutes: IRouterPattern[] = [];
if (Localization.activateCreditDebitMemo) {
  memoRoutes = [
    {
      path: '/record-memo/:id',
      pageComponent: 'Memo/record-memo',
      layout: DashboardTemplate,
    },
  ];
}

const loginRoute: IRouterPattern[] = [
  {
    path: '/login',
    noAuth: true,
    pageComponent: 'Login',
  },
  {
    path: '/borrower/login',
    noAuth: true,
    pageComponent: 'Login',
  },
  {
    path: '/forgot-password',
    noAuth: true,
    pageComponent: 'Login/forgot-password',
  },
  {
    path: '/reset-password',
    noAuth: false,
    pageComponent: 'Login/reset-password',
  },
  {
    path: '/reset-password-success',
    noAuth: true,
    pageComponent: 'Login/reset-password-success',
  },
];

const dashboardRoutes: IRouterPattern[] = [
  {
    path: '/dashboard',
    pageComponent: 'Dashboard',
    layout: DashboardTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    layout: DashboardTemplate,
  },
];

const superuserRoutes: IRouterPattern[] = [
  {
    path: '/broadcast/add',
    pageComponent: 'Superuser/broadcast-add',
    layout: SuperuserTemplate,
  },
  {
    path: '/broadcast/view',
    pageComponent: 'Superuser/broadcast-view',
    layout: SuperuserTemplate,
  },
  {
    path: '/broadcast/detail/:id',
    pageComponent: 'Superuser/broadcast-detail',
    layout: SuperuserTemplate,
  },
];

const dealerRoutes: IRouterPattern[] = [
  {
    path: '/active/:id',
    pageComponent: 'Dealer/account-detail',
    layout: DealerTemplate,
  },
  {
    path: '/matured/:id',
    pageComponent: 'Dealer/account-detail',
    layout: DealerTemplate,
  },
  {
    path: '/active',
    pageComponent: 'Dashboard',
    layout: DealerTemplate,
  },
  {
    path: '/matured',
    pageComponent: 'Dashboard',
    layout: DealerTemplate,
  },
  {
    path: '/record-payment/:id',
    pageComponent: 'Payment/record-payment',
    layout: DashboardTemplate,
  },
];

const borrowerRoutes: IRouterPattern[] = [
  {
    path: '/detail/:id',
    pageComponent: 'Customer/detail',
    layout: CustomerTemplate,
  },
];

const cashierRoutes: IRouterPattern[] = [
  {
    path: '/record-payment/:id',
    pageComponent: 'Payment/record-payment',
    layout: DashboardTemplate,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const adminRoutes: IRouterPattern[] = [
  {
    path: '/version',
    noAuth: true,
    pageComponent: 'Version',
  },
  {
    path: '/collection-detail/:id',
    pageComponent: 'Collection/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist-detail/:id',
    pageComponent: 'Specialist/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll-details/:id',
    pageComponent: 'Scroll/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/repo-details/:id',
    pageComponent: 'Collection/repo-details',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist-itinerary',
    pageComponent: 'Specialist/itinerary',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist',
    pageComponent: 'Specialist/list',
    layout: DashboardTemplate,
  },
  {
    path: '/skipped-visit',
    pageComponent: 'Collection/skip-visit',
    layout: DashboardTemplate,
  },
  {
    path: '/cashier-detail/:id',
    pageComponent: 'Cashier/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/cashiers',
    pageComponent: 'Cashier/list',
    layout: DashboardTemplate,
  },
  {
    path: '/branches',
    pageComponent: 'Branch/list',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll',
    pageComponent: 'Scroll/list',
    layout: DashboardTemplate,
  },
  {
    path: '/cs-itinerary',
    pageComponent: 'CsItinerary',
    layout: DashboardTemplate,
  },
  {
    path: '/accounts',
    pageComponent: 'Account/list',
    layout: DashboardTemplate,
  },
  {
    path: '/account/:id',
    pageComponent: 'Account/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/task',
    pageComponent: 'Task/list',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/daily-collections',
    pageComponent: 'DailyCollection/list',
    layout: DashboardTemplate,
  },
  {
    path: '/reports-downloads',
    pageComponent: 'DailyCollection/reports',
    layout: DashboardTemplate,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const rpaRoutes: IRouterPattern[] = [
  {
    path: '/',
    pageComponent: 'Rpa',
  },
];

const receiptRoutes: IRouterPattern[] = [
  {
    path: '/r/:id',
    pageComponent: 'Receipt',
    noAuth: true,
  },
];

const getRoutes = (role: string) => {
  switch (role) {
    case 'R':
      return [...loginRoute, ...rpaRoutes];
    case 'A':
      return [...loginRoute, ...cashierRoutes, ...dashboardRoutes];
    case 'C':
    case 'M':
      return [...loginRoute, ...adminRoutes, ...dashboardRoutes];
    case 'D':
      return [
        ...dealerRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: DealerTemplate,
        })),
      ];
    case 'B':
      return [
        ...borrowerRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: CustomerTemplate,
        })),
      ];
    case 'S':
      return [
        ...superuserRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: SuperuserTemplate,
        })),
      ];
    default:
      return [...loginRoute, ...receiptRoutes, ...dashboardRoutes, ...cashierRoutes];
  }
};

export default getRoutes;
